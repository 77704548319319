import React, { useEffect, Fragment } from "react";
import { Button, Container } from "react-bootstrap";
import edit from "../../assets/images/icons/edit.svg";
import deleteimage from "../../assets/images/icons/delete.svg";
import addicon from "../../assets/images/icons/addicon.svg";
import searchicon from "../../assets/images/icons/search.svg";
import bactBtnIcon from "../../assets/images/icons/bactBtn.svg";
// axios
import axios from "axios";

// apipath
import Apipath from "../../config/apipath";
import useState from "react-usestateref";
import ReactPaginate from "react-paginate";

const SizeChart = () => {
  const [gridDisplay, setGridDisplay] = useState("block");
  const [addDisplay, setAddDisplay] = useState("none");
  const [errorMsgText, setErrorMsgText] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [sizeList, setSizeList] = useState([]);
  const [size, setSize] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectActiveId, setSelectActiveId] = useState("2");
  const [currentPage, setCurrentPage, currentPageRef] = useState(1);
  const [skipCount, setSkipCount, skipCountRef] = useState(6);
  const [limitCount, setLimitCount, limitCountRef] = useState(6);
  const [totalPageCount, setTotalPageCount, totalPageCountRef] = useState(0);

  useEffect(() => {
    init();
  }, []);

  function init() {
    try {
      defaultValue();
      getSizeList();
    } catch (error) {
      console.log("Error", error);
    }
  }

  function defaultValue() {
    try {
      setGridDisplay("block");
      setAddDisplay("none");
      if (sessionStorage.getItem("edit_data") == null) {
        setHeaderTitle("Add Size");
        setButtonName("Save");
      } else {
        let data = JSON.parse(sessionStorage.getItem("edit_data"));
        setHeaderTitle("Edit Size");
        setButtonName("Update");
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  async function addBtn() {
    try {
      await clearData();
      setGridDisplay("none");
      setAddDisplay("block");
      setHeaderTitle("Add Size");
      setButtonName("Save");
    } catch (error) {
      console.log("Error", error);
    }
  }

  function clearData() {
    try {
      setSize("");
      document.getElementById("category_checkbox").checked = false;
      setErrorMsgText("");
      sessionStorage.removeItem("edit_data");
    } catch (error) {
      console.log("Error", error);
    }
  }

  function editBtn(itemList) {
    try {
      sessionStorage.setItem("edit_data", JSON.stringify(itemList));
      setSize(itemList.measurement_name);
      document.getElementById("category_checkbox").checked =
        itemList.is_active == 1 ? true : false;
      setHeaderTitle("Edit Size");
      setButtonName("Update");
      setErrorMsgText("");
      setGridDisplay("none");
      setAddDisplay("block");
    } catch (error) {
      console.log("Error", error);
    }
  }

  function bactBtn() {
    try {
      setGridDisplay("block");
      setAddDisplay("none");
    } catch (error) {
      console.log("Error", error);
    }
  }

  function getSizeList() {
    try {
      if (window.GetAllSizeChart) {
        window.GetAllSizeChart.cancel("Operation canceled due to new request.");
      }

      window.GetAllSizeChart = axios.CancelToken.source();
      setLoader(true);

      // Get filter values
      const search = document.getElementById("search").value.trim();
      const filterActive = parseInt(
        document.getElementById("filter_active_dropdown").value,
        10
      );

      let postData = {
        search: search,
        limit: skipCountRef.current,
        skip_count:
          skipCountRef.current * currentPageRef.current - skipCountRef.current,
        is_active: filterActive,
      };

      axios({
        method: "post",
        url: Apipath["GetAllSizeChart"],
        headers: {
          "Content-Type": "application/json",
        },
        data: postData,
        cancelToken: window.GetAllSizeChart.token,
      })
        .then(function (response) {
          setLoader(false);

          if (response.data && Array.isArray(response.data)) {
            const dataLength = response.data.length;
            setSizeList(response.data);
            setTotalPageCount(Math.ceil(dataLength / limitCountRef.current));
          } else {
            setSizeList([]);
            setTotalPageCount(0);
          }
        })
        .catch(function (error) {
          setLoader(false);
          if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
          } else {
            console.error("Error fetching size list:", error);
          }
        });
    } catch (error) {
      setLoader(false);
      console.error("Error in getSizeList function:", error);
    }
  }

  function handlePageClick(e) {
    try {
      let value = e.selected + 1;
      if (currentPageRef.current != value) {
        setCurrentPage(value);
        getSizeList();
      }
    } catch (error) {
      console.log("error : ", error);
    }
  }

  function activeOnChange(is_active, id) {
    try {
      let user_data = JSON.parse(sessionStorage.getItem("admin_detatils"));
      let postData = {
        is_active: is_active == 1 ? 0 : 1,
        update_by: user_data[0].user_id,
        updated_date: new Date()
          .toLocaleString("en-ZA")
          .replace(",", "")
          .replace(/\//g, "-"),
      };
      axios({
        method: "put",
        url: Apipath["UpdateSizeChart"] + id,
        headers: {
          "Content-Type": "application/json",
        },
        data: postData,
      })
        .then(function (response) {
          if (response.data.error_code == "9999") {
            getSizeList();
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {}
  }

  function saveBtn() {
    try {
      let validate = validation();
      if (validate) {
        if (sessionStorage.getItem("edit_data") == null) {
          document.getElementById("save_btn").style.display = "none";
          document.getElementById("loder_btn").style.display = "block";
          let postData = {
            sizeChart_measurement_id: "0",
            measurement_name: document.getElementById("size").value.trim(),
            is_active:
              document.getElementById("category_checkbox").checked == true
                ? 1
                : 0,
            created_date: new Date()
              .toLocaleString("en-ZA")
              .replace(",", "")
              .replace(/\//g, "-"),
            updated_date: new Date()
              .toLocaleString("en-ZA")
              .replace(",", "")
              .replace(/\//g, "-"),
          };
          axios({
            method: "post",
            url: Apipath["CreateSizeChartMaster"],
            headers: {
              "Content-Type": "application/json",
            },
            data: postData,
          })
            .then(function (response) {
              document.getElementById("save_btn").style.display = "block";
              document.getElementById("loder_btn").style.display = "none";
              if (response.data.error_code == "9999") {
                setGridDisplay("block");
                setAddDisplay("none");
                setCurrentPage(1);
                getSizeList();
              } else if (response.data.error_code == "9998") {
                setErrorMsgText(response.data.message);
              } else {
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          let data = JSON.parse(sessionStorage.getItem("edit_data"));
          let postData = {
            measurement_name: document.getElementById("size").value,
            is_active:
              document.getElementById("category_checkbox").checked == true
                ? 1
                : 0,
          };
          axios({
            method: "put",
            url: Apipath["UpdateSizeChart"] + data.sizeChart_measurement_id,
            headers: {
              "Content-Type": "application/json",
            },
            data: postData,
          })
            .then(function (response) {
              if (response.data.error_code == "9999") {
                setGridDisplay("block");
                setAddDisplay("None");
                getSizeList();
              } else {
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  function validation() {
    try {
      if (document.getElementById("size").value == "") {
        document.getElementById("size").focus();
        setErrorMsgText("Please enter the size name.");
        return false;
      } else {
        setErrorMsgText("");
        return true;
      }
    } catch (error) {
      console.log("Error during validation: ", error);
      return false;
    }
  }

  function clearBtn() {
    try {
      document.getElementById("search").value = "";
      setSelectActiveId("2");
      document.getElementById("filter_active_dropdown").value = "2";
      setCurrentPage(1);
      getSizeList();
    } catch (error) {
      console.log("Error", error);
    }
  }

  useEffect(() => {
    console.log("size", sizeList);
  }, []);
  return (
    <Fragment>
      <div>
        <div className="card W-100 p-1" style={{ display: gridDisplay }}>
          <div className="card-header">Size Chart</div>
          <div className="table_card my-3 mx-auto">
            <div className="row justify-content-end">
              <div className="col-sm-6 mt-1 col-md-4 col-lg-3 col-xl-3 text-end">
                <button className="addBtn" onClick={addBtn}>
                  Add Size
                  <img src={addicon} className="action_icon ms-2" alt="Add" />
                </button>
              </div>
            </div>
            <div className="row justify-content-end align-items-end">
              <div className="col-sm-6 mt-1 col-md-4 col-lg-3 col-xl-3">
                <label className="label_text">Status</label>
                <select
                  id="filter_active_dropdown"
                  className="input_tag"
                  value={selectActiveId}
                  onChange={(e) => {
                    setSelectActiveId(e.target.value);
                    setCurrentPage(1);
                    getSizeList();
                  }}
                >
                  <option value="2">select</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div className="mt-1 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div className="align-items-center input_div">
                  <input
                    type="search"
                    placeholder="Search sizes..."
                    className="input_box"
                    id="search"
                    onChange={() => {
                      setCurrentPage(1);
                      getSizeList();
                    }}
                  />
                  <img src={searchicon} className="inputlogo" alt="Search" />
                </div>
              </div>
              <div className="col-sm-4 mt-1 col-md-4 col-lg-2 col-xl-2">
                <button className="addBtn" onClick={clearBtn}>
                  Clear
                </button>
              </div>
            </div>
            <div
              className="mt-2"
              style={{ height: "53vh", overflowY: "scroll" }}
            >
              <table className="w-100">
                <thead>
                  <tr className="thead_border">
                    <th>S.No</th>
                    <th>Size Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader && (
                    <tr className="text-center">
                      <td colSpan="4">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  )}
                  {!loader && sizeList.length === 0 && (
                    <tr className="text-center">
                      <td colSpan="4" className="nofount_text">
                        No Data Found
                      </td>
                    </tr>
                  )}
                  {!loader &&
                    sizeList.length > 0 &&
                    sizeList.map((sizeItem, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{sizeItem.measurement_name}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={sizeItem.is_active === 1}
                            onClick={() =>
                              activeOnChange(
                                sizeItem.is_active,
                                sizeItem.sizeChart_measurement_id
                              )
                            }
                          />
                        </td>
                        <td>
                          <img
                            src={edit}
                            className="action_icon"
                            alt="Edit"
                            onClick={() => editBtn(sizeItem)}
                          />
                          {/* <img
                            src={deleteimage}
                            className="action_icon ms-2"
                            alt="Delete"
                            onClick={() => deleteBtn(sizeItem)}
                          /> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {totalPageCount > 0 && (
              <div className="d-flex justify-content-end align-items-center mt-3">
                <ReactPaginate
                  pageCount={totalPageCount}
                  onPageChange={handlePageClick}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  previousLabel={"<"}
                  nextLabel={">"}
                  forcePage={currentPage - 1}
                />
              </div>
            )}
          </div>
        </div>

        {/* Add/Edit Size Form */}
        <div className="card W-100 p-3" style={{ display: addDisplay }}>
          <div className="card-header d-flex gap-3 align-items-center">
            <img
              src={bactBtnIcon}
              className="back_btn"
              alt="Back"
              onClick={bactBtn}
            />
            {headerTitle}
          </div>
          <div className="addEditCard my-3 mx-auto">
            <div className="row mt-3 mb-3">
              <div className="col-sm-12 mt-1 col-md-8 col-lg-6 col-xl-6">
                <div className="row justify-content-end">
                  <div className="col-xl-12 mt-2">
                    <label className="label_text">Size Name</label>
                    <input
                      type="text"
                      placeholder="Enter"
                      className="input_tag"
                      id="size"
                      value={size}
                      onChange={(e) => {
                        setSize(e.target.value);
                        setErrorMsgText("");
                      }}
                    />
                  </div>
                  <div className="col-xl-12 mt-3">
                    <div className="active_btn">
                      <span className="label_text">Status</span>
                      <input type="checkbox" id="category_checkbox" />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="d-flex justify-content-center">
                      {errorMsgText && (
                        <div className="errormsg">{errorMsgText}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-4 mt-3">
                    <button className="addBtn" id="save_btn" onClick={saveBtn}>
                      {buttonName}
                    </button>
                    <button
                      className="addBtn"
                      id="loder_btn"
                      style={{ display: "none" }}
                      disabled
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                        style={{ height: "20px", width: "20px" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SizeChart;
