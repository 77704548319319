// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table th {
  position: relative;
}

.table td {
  position: relative;
}

.table .removeBtn {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  border: 1px solid black;
  color: black;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 4px;
  padding: 0;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  /* border-radius: 50%; */
}
`, "",{"version":3,"sources":["webpack://./src/assets/scss/pages/sizeChart.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,iBAAiB;EACjB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,UAAU;EACV,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,wBAAwB;AAC1B","sourcesContent":[".table th {\n  position: relative;\n}\n\n.table td {\n  position: relative;\n}\n\n.table .removeBtn {\n  display: inline-block;\n  position: absolute;\n  top: 0;\n  right: 0;\n  background: white;\n  border: 1px solid black;\n  color: black;\n  cursor: pointer;\n  font-size: 1.2rem;\n  margin: 4px;\n  padding: 0;\n  width: 15px;\n  height: 15px;\n  line-height: 15px;\n  text-align: center;\n  /* border-radius: 50%; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
