import React, { useEffect, Fragment, useRef } from "react";
import useState from "react-usestateref";
import "../../../src/assets/scss/pages/sizeChart.css";
import { Button } from "react-bootstrap";
import axios from "axios";
import Apipath from "../../config/apipath";
import addicon from "../../assets/images/icons/addicon.svg";
import searchicon from "../../assets/images/icons/search.svg";
import bactBtnIcon from "../../assets/images/icons/bactBtn.svg";
import edit from "../../assets/images/icons/edit.svg";
import ReactPaginate from "react-paginate";

const SizeChartForm = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [productSize, setProductSize] = useState([]);
  const [sizeMeasurement, setSizeMeasurement] = useState([]);
  const [sizeChartPattern, setSizeChartPattern] = useState({});
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedMeasurements, setSelectedMeasurements] = useState([]);
  const [showSizeDropdown, setShowSizeDropdown] = useState(false);
  const [showMeasurementDropdown, setShowMeasurementDropdown] = useState(false);
  const [image, setImage, imageRef] = useState("");
  const [imageError, setImageError] = useState("");
  const [gridDisplay, setGridDisplay] = useState("block");
  const [addDisplay, setAddDisplay] = useState("none");
  const [sizeChartList, setSizeChartList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [errorMsgText, setErrorMsgText] = useState("");
  const [sizeChartName, setSizeChartName] = useState("");
  const [productType, setProductType] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [status, setStatus] = useState(false);
  const [buttonName, setButtonName] = useState("Save");
  const [headerTitle, setHeaderTitle] = useState("Add Size Chart");
  const [selectActiveId, setSelectActiveId] = useState("2");
  const [totalPageCount, setTotalPageCount, totalPageCountRef] = useState(0);
  const [currentPage, setCurrentPage, currentPageRef] = useState(1);
  const [skipCount, setSkipCount, skipCountRef] = useState(6);
  const [limitCount, setLimitCount, limitCountRef] = useState(6);
  const [subCategory, setSubCategory] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoryType, setSubCategoryType] = useState("");

  useEffect(() => {
    setLoader(false);
  }, []);

  useEffect(() => {
    init();
  }, []);

  function init() {
    defaultValue();
    getCategoryList();
    getProductTypeDataList();
    getProductSizedataList();
    getSizeMeasurementDataList();
    getSizeChartList();
  }

  function getCategoryList() {
    try {
      if (window.GetCategoryList) {
        window.GetCategoryList.cancel();
      }
      window.GetCategoryList = axios.CancelToken.source();
      setLoader(true);
      let postData = {
        search: document.getElementById("search").value,
        limit: skipCountRef.current,
        skip_count:
          skipCountRef.current * currentPageRef.current - skipCountRef.current,
        is_active: parseInt(
          document.getElementById("filter_active_dropdown").value
        ),
      };
      axios({
        method: "post",
        url: Apipath["GetCategoryList"],
        headers: {
          "Content-Type": "application/json",
        },
        data: postData,
        cancelToken: window.GetCategoryList.token,
      })
        .then(function (response) {
          setLoader(false);
          if (response.data.error_code == "9999") {
            if (response.data.data[0].data.length > 0) {
              setCategories(response.data.data[0].data);
              setTotalPageCount(
                Math.ceil(
                  response.data.data[0].pagination[0].total /
                    limitCountRef.current
                )
              );
            } else {
              setCategories([]);
              setTotalPageCount(0);
            }
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log("Error", error);
    }
  }

  function getSizeChartList() {
    try {
      if (window.GetAllSizeChartPattern) {
        window.GetAllSizeChartPattern.cancel(
          "Operation canceled due to new request."
        );
      }

      window.GetAllSizeChartPattern = axios.CancelToken.source();
      setLoader(true);

      const search = document.getElementById("search").value.trim();
      const filterActive = parseInt(
        document.getElementById("filter_active_dropdown").value,
        10
      );

      let postData = {
        search: search,
        limit: skipCountRef.current,
        skip_count:
          skipCountRef.current * currentPageRef.current - skipCountRef.current,
        is_active: filterActive,
      };

      axios({
        method: "post",
        url: Apipath["GetAllSizeChartPattern"],
        headers: {
          "Content-Type": "application/json",
        },
        data: postData,
        cancelToken: window.GetAllSizeChartPattern.token,
      })
        .then(function (response) {
          setLoader(false);

          if (response.data && Array.isArray(response.data)) {
            const dataLength = response.data.length;
            setSizeChartList(response.data);
            setTotalPageCount(Math.ceil(dataLength / limitCountRef.current));
          } else {
            setSizeChartList([]);
            setTotalPageCount(0);
          }
        })
        .catch(function (error) {
          setLoader(false);
          if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
          } else {
            console.error("Error fetching size list:", error);
          }
        });
    } catch (error) {
      setLoader(false);
      console.error("Error in getSizeList function:", error);
    }
  }

  async function getProductSizedataList() {
    try {
      await fetch(Apipath["ProductSizeDropdownList"])
        .then((response) => response.json())
        .then((data) => {
          const sizeNames = data.data.map((item) => item.size_Name);
          setProductSize(sizeNames);
        })
        .catch((error) => console.error("Error fetching sizes:", error));
    } catch (error) {
      console.log("Error", error);
    }
  }

  async function getSizeMeasurementDataList() {
    try {
      await fetch(Apipath["GetSizeChartById"])
        .then((response) => response.json())
        .then((data) => {
          const activeMeasurements = data.filter(
            (measurement) => measurement.is_active === 1
          );
          const measurementNames = activeMeasurements.map(
            (item) => item.measurement_name
          );
          setSizeMeasurement(measurementNames);
        })
        .catch((error) => console.error("Error fetching measurements:", error));
    } catch (error) {
      console.log("Error", error);
    }
  }

  async function getProductTypeDataList() {
    try {
      await fetch(Apipath["ProductTypeDropdownList"])
        .then((response) => response.json())
        .then((responseData) => {
          const data = responseData.data || responseData;
          if (Array.isArray(data)) {
            const productTypes = data.map((item) => ({
              product_type_id: item.product_type_id,
              product_type_name: item.product_type_name,
              category_id: item.category_id,
              sub_Category_id: item.sub_Category_id,
            }));
            setProducts(productTypes);
          } else {
            console.error(
              "Unexpected response format, data is not an array:",
              data
            );
          }
        })
        .catch((error) =>
          console.error("Error fetching product types:", error)
        );
    } catch (error) {
      console.log("Error", error);
    }
  }

  function addBtn() {
    try {
      setGridDisplay("none");
      setAddDisplay("block");
      setHeaderTitle("Add Size Chart");
      setButtonName("Save");
      setErrorMsgText("");
      clearData();
    } catch (error) {
      console.log("Error", error);
    }
  }

  function bactBtn() {
    setGridDisplay("block");
    setAddDisplay("none");
  }

  function validation() {
    try {
      if (document.getElementById("size_chart_name").value == "") {
        document.getElementById("size_chart_name").focus();
        setErrorMsgText("Please enter the size chart name.");
        return false;
      } else {
        setErrorMsgText("");
        return true;
      }
    } catch (error) {
      console.log("Error during validation: ", error);
      return false;
    }
  }

  function defaultValue() {
    try {
      setGridDisplay("block");
      setAddDisplay("none");
      if (sessionStorage.getItem("edit_data") == null) {
        setHeaderTitle("Add Size");
        setButtonName("Save");
      } else {
        let data = JSON.parse(sessionStorage.getItem("edit_data"));
        setHeaderTitle("Edit Size");
        setButtonName("Update");
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  function saveSizeChart() {
    try {
      console.log("current", imageRef.current);

      let validate = validation();
      if (validate) {
        if (sessionStorage.getItem("edit_data") == null) {
          document.getElementById("save_btn").style.display = "none";
          document.getElementById("loader_btn").style.display = "block";

          let postData = {
            size_chart_id: "0",
            size_chart_name: document
              .getElementById("size_chart_name")
              .value.trim(),
            category_id: document.getElementById("category_type").value,
            product_type_id: document.getElementById("product_type").value,
            size_chart_pattern: sizeChartPattern,
            image_url: imageRef.current,
            is_active:
              document.getElementById("category_checkbox").checked == true
                ? 1
                : 0,
            created_date: new Date()
              .toLocaleString("en-ZA")
              .replace(",", "")
              .replace(/\//g, "-"),
            updated_date: new Date()
              .toLocaleString("en-ZA")
              .replace(",", "")
              .replace(/\//g, "-"),
          };
          axios({
            method: "post",
            url: Apipath["CreateSizeChartPattern"],
            headers: {
              "Content-Type": "application/json",
            },
            data: postData,
          })
            .then(function (response) {
              document.getElementById("save_btn").style.display = "block";
              document.getElementById("loader_btn").style.display = "none";
              if (response.data.error_code == "9999") {
                setGridDisplay("block");
                setAddDisplay("none");
                setCurrentPage(1);
                getSizeChartList();
              } else if (response.data.error_code == "9998") {
                setErrorMsgText(response.data.message);
              } else {
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          let data = JSON.parse(sessionStorage.getItem("edit_data"));
          let postData = {
            size_chart_id: data.size_chart_id,
            size_chart_name: document.getElementById("size_chart_name").value,
            category_id: document.getElementById("category_type").value,
            product_type_id: document.getElementById("product_type").value,
            size_chart_pattern: sizeChartPattern,
            image_url: imageRef.current,
            is_active:
              document.getElementById("category_checkbox").checked == true
                ? 1
                : 0,
          };
          axios({
            method: "put",
            url: Apipath["UpdateSizeChartPattern"] + data.size_chart_id,
            headers: {
              "Content-Type": "application/json",
            },
            data: postData,
          })
            .then(function (response) {
              if (response.data.error_code == "9999") {
                setGridDisplay("block");
                setAddDisplay("None");
                getSizeChartList();
              } else {
              }
            })
            .catch(function (error) {
              console.log("Error", error);
            });
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  function editBtn(itemList) {
    try {
      sessionStorage.setItem("edit_data", JSON.stringify(itemList));
      setSizeChartName(itemList.size_chart_name);
      setCategoryType(itemList.category_id);
      setProductType(itemList.product_type_id);
      setSizeChartPattern(itemList.size_chart_pattern);
      const sizes = Object.keys(itemList.size_chart_pattern);
      const measurements =
        sizes.length > 0
          ? Object.keys(itemList.size_chart_pattern[sizes[0]])
          : [];
      setSelectedSizes(sizes);
      setSelectedMeasurements(measurements);
      setImage(itemList.image_url ? itemList.image_url : null);
      setStatus(itemList.is_active === 1);
      setButtonName("Update");
      setHeaderTitle("Edit Size Chart");
      setGridDisplay("none");
      setAddDisplay("block");
      setErrorMsgText("");
    } catch (error) {
      console.log("Error", error);
    }
  }

  function activeOnChange(is_active, id) {
    try {
      let user_data = JSON.parse(sessionStorage.getItem("admin_detatils"));
      let postData = {
        is_active: is_active == 1 ? 0 : 1,
        update_by: user_data[0].user_id,
        updated_date: new Date()
          .toLocaleString("en-ZA")
          .replace(",", "")
          .replace(/\//g, "-"),
      };
      axios({
        method: "put",
        url: Apipath["UpdateSizeChartPattern"] + id,
        headers: {
          "Content-Type": "application/json",
        },
        data: postData,
      })
        .then(function (response) {
          if (response.data.error_code == "9999") {
            getSizeChartList();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log("Error", error);
    }
  }

  function clearData() {
    try {
      setSizeChartName("");
      setCategoryType("");
      setProductType("");
      setSizeChartPattern("");
      setSelectedSizes([]);
      setSelectedMeasurements([]);
      setImage("");
      document.getElementById("category_checkbox").checked = false;
      setErrorMsgText("");
      sessionStorage.removeItem("edit_data");
    } catch (error) {
      console.log("Error", error);
    }
  }

  function clearBtn() {
    try {
      document.getElementById("search").value = "";
      setSelectActiveId("2");
      document.getElementById("filter_active_dropdown").value = "2";
      setCurrentPage(1);
    } catch (error) {
      console.log("Error", error);
    }
  }

  function handlePageClick(e) {
    try {
      let value = e.selected + 1;
      if (currentPageRef.current != value) {
        setCurrentPage(value);
        getSizeChartList();
      }
    } catch (error) {
      console.log("error : ", error);
    }
  }

  const addSize = (size) => {
    if (size && !selectedSizes.includes(size)) {
      setSelectedSizes([...selectedSizes, size]);
    }
    setShowSizeDropdown(false);
  };

  const addMeasurement = (measurement) => {
    if (measurement && !selectedMeasurements.includes(measurement)) {
      setSelectedMeasurements([...selectedMeasurements, measurement]);
    }
    setShowMeasurementDropdown(false);
  };

  const removeSize = (size) => {
    const updatedSizes = selectedSizes.filter((s) => s !== size);
    setSelectedSizes(updatedSizes);

    setSizeChartPattern((prevPattern) => {
      const updatedPattern = { ...prevPattern };
      delete updatedPattern[size];
      return updatedPattern;
    });
  };

  const removeMeasurement = (measurement) => {
    const updatedMeasurements = selectedMeasurements.filter(
      (m) => m !== measurement
    );
    setSelectedMeasurements(updatedMeasurements);

    setSizeChartPattern((prevPattern) => {
      const updatedPattern = { ...prevPattern };
      Object.keys(updatedPattern).forEach((size) => {
        if (updatedPattern[size]) {
          delete updatedPattern[size][measurement];
        }
      });
      return updatedPattern;
    });
  };

  const handleMeasurementChange = (size, measurement, value) => {
    setSizeChartPattern((prevPattern) => ({
      ...prevPattern,
      [size]: {
        ...prevPattern[size],
        [measurement]: parseFloat(value) || "",
      },
    }));
  };

  const handleImageChange = async (e) => {
    try {
      console.log("fd", imageRef);
      const files = e.target.files;
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });
      if (imageRef.current != "") {
        await axios({
          method: "delete",
          url: Apipath["DeleteImage"] + imageRef.current,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            if (response.data.error_code == "9999") {
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      await fetch(Apipath["ImageUpload"], {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error_code == "9999") {
            setImage(data.data[0]);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    setCategoryType(selectedCategoryId);
    setSubCategoryType(""); 
    const selectedCategory = categories.find(
      (category) => category.category_id === selectedCategoryId
    );

    if (selectedCategory && selectedCategory.category.length > 0) {
      setSubCategories(selectedCategory.category);
      setFilteredProducts([]); 
    } else {
      setSubCategories([]); 

      const filtered = products.filter(
        (product) => product.category_id === selectedCategoryId
      );
      setFilteredProducts(filtered);
    }
  };

  const handleSubCategoryChange = (e) => {
    const selectedSubCategoryId = e.target.value;
    setSubCategoryType(selectedSubCategoryId);

    const filtered = products.filter(
      (product) =>
        product.category_id === categoryType &&
        product.sub_Category_id === selectedSubCategoryId
    );
    setFilteredProducts(filtered);
  };

  return (
    <Fragment>
      <div>
        <div className="card W-100 p-1" style={{ display: gridDisplay }}>
          <div className="card-header">Size Chart</div>
          <div className="table_card my-3 mx-auto">
            <div className="row justify-content-end">
              <div className="col-sm-6 mt-1 col-md-4 col-lg-3 col-xl-3 text-end">
                <button className="addBtn" onClick={() => addBtn()}>
                  Add Size Chart
                  <img src={addicon} className="action_icon ms-2" alt="Add" />
                </button>
              </div>
              <div className="row justify-content-end align-items-end">
                <div className="col-sm-6 mt-1 col-md-4 col-lg-3 col-xl-3">
                  <label className="label_text">Status</label>
                  <select
                    name="cars"
                    id="filter_active_dropdown"
                    className="input_tag"
                    value={selectActiveId}
                    onChange={(e) => {
                      setSelectActiveId(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="2">select</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
                <div className=" mt-1 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <div className=" align-items-center input_div">
                    <input
                      type="search"
                      placeholder="Search.."
                      className="input_box"
                      id="search"
                      onChange={() => {
                        setCurrentPage(1);
                      }}
                    />
                    <img src={searchicon} className="inputlogo"></img>
                  </div>
                </div>
                <div className="col-sm-4 mt-1 col-md-4 col-lg-2 col-xl-2">
                  <button className="addBtn" onClick={() => clearBtn()}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div
              className="table-responsive mt-2"
              style={{ height: "53vh", overflowY: "scroll" }}
            >
              <table className="w-100">
                <thead>
                  <tr className="thead_border">
                    <th>S.No</th>
                    <th>Size Chart Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader && (
                    <tr className="text-center" colSpan="4">
                      <td colSpan="4">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  )}
                  {!loader && sizeChartList.length === 0 && (
                    <tr className="text-center" colSpan="4">
                      <td colSpan="4" className="nofount_text">
                        No Data Found
                      </td>
                    </tr>
                  )}
                  {!loader &&
                    sizeChartList.length > 0 &&
                    sizeChartList.map((chart, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{chart.size_chart_name}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={chart.is_active === 1}
                            onClick={() =>
                              activeOnChange(
                                chart.is_active,
                                chart.size_chart_id
                              )
                            }
                          />
                        </td>
                        <td>
                          <img
                            src={edit}
                            className="action_icon"
                            onClick={() => editBtn(chart)}
                            alt="Edit"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {totalPageCount > 0 && (
              <div className="d-flex justify-content-end align-items-center mt-3">
                <ReactPaginate
                  pageCount={totalPageCount}
                  onPageChange={handlePageClick}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  previousLabel={"<"}
                  nextLabel={">"}
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        </div>
        <div className="card W-100 p-3" style={{ display: addDisplay }}>
          <div className="card-header d-flex gap-3 align-items-center">
            <img
              src={bactBtnIcon}
              className="back_btn"
              onClick={() => bactBtn()}
              alt="Back"
            />
            {headerTitle}
          </div>
          <div className="addEditCard my-3 mx-auto">
            <div className="row mt-3 mb-3">
              <div className="col-sm-12 mt-1 col-md-8 col-lg-6 col-xl-6">
                <div className="row justify-content-end">
                  <div className="col-xl-12">
                    <label className="label_text">Size Chart Name</label>
                    <input
                      type="text"
                      placeholder="Enter"
                      className="input_tag"
                      id="size_chart_name"
                      value={sizeChartName}
                      onChange={(e) => setSizeChartName(e.target.value)}
                    />
                  </div>
                  <div className="col-xl-12">
                    <label className="label_text">Category Type</label>
                    <select
                      className="input_tag"
                      id="category_type"
                      value={categoryType}
                      onChange={handleCategoryChange}
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option
                          key={category.category_id}
                          value={category.category_id}
                        >
                          {category.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {subCategories.length > 0 && (
                    <div className="col-xl-12">
                      <label className="label_text">Subcategory</label>
                      <select
                        className="input_tag"
                        id="subcategory_type"
                        value={subCategoryType}
                        onChange={handleSubCategoryChange}
                      >
                        <option value="">Select Subcategory</option>
                        {subCategories.map((subCategory) => (
                          <option
                            key={subCategory.sub_Category_id}
                            value={subCategory.sub_Category_id}
                          >
                            {subCategory.sub_category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="col-xl-12">
                    <label className="label_text">Product Type</label>
                    <select
                      className="input_tag"
                      id="product_type"
                      value={productType}
                      onChange={(e) => setProductType(e.target.value)}
                    >
                      <option value="">Select Product</option>
                      {filteredProducts.length > 0 ? (
                        filteredProducts.map((product) => (
                          <option
                            key={product.product_type_id}
                            value={product.product_type_id}
                          >
                            {product.product_type_name}
                          </option>
                        ))
                      ) : (
                        <option value="">No Products Available</option>
                      )}
                    </select>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <label className="label_text">Size Chart Pattern</label>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Size</th>
                            {selectedMeasurements.map((measurement) => (
                              <th key={measurement}>
                                {measurement}
                                <button
                                  className="removeBtn"
                                  onClick={() => removeMeasurement(measurement)}
                                >
                                  -
                                </button>
                              </th>
                            ))}
                            <th>
                              {!showMeasurementDropdown ? (
                                <button
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    fontSize: "1.2rem",
                                  }}
                                  onClick={() =>
                                    setShowMeasurementDropdown(true)
                                  }
                                >
                                  +
                                </button>
                              ) : (
                                <select
                                  onChange={(e) =>
                                    addMeasurement(e.target.value)
                                  }
                                  value=""
                                  style={{ marginLeft: "10px" }}
                                >
                                  <option value="">Select Measurement</option>
                                  {sizeMeasurement
                                    .filter(
                                      (m) => !selectedMeasurements.includes(m)
                                    )
                                    .map((measurement) => (
                                      <option
                                        key={measurement}
                                        value={measurement}
                                      >
                                        {measurement}
                                      </option>
                                    ))}
                                </select>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedSizes.map((size) => (
                            <tr key={size}>
                              <td>
                                {size}
                                <button
                                  className="removeBtn"
                                  onClick={() => removeSize(size)}
                                >
                                  -
                                </button>
                              </td>
                              {selectedMeasurements.map((measurement) => (
                                <td key={measurement}>
                                  <input
                                    type="number"
                                    className="input_tag"
                                    value={
                                      sizeChartPattern[size]?.[measurement] ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleMeasurementChange(
                                        size,
                                        measurement,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                          <tr>
                            <td>
                              {!showSizeDropdown ? (
                                <button
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    fontSize: "1.2rem",
                                  }}
                                  onClick={() => setShowSizeDropdown(true)}
                                >
                                  +
                                </button>
                              ) : (
                                <select
                                  onChange={(e) => addSize(e.target.value)}
                                  value=""
                                  style={{ marginLeft: "10px" }}
                                >
                                  <option value="">Select Size</option>
                                  {productSize
                                    .filter((s) => !selectedSizes.includes(s))
                                    .map((size) => (
                                      <option key={size} value={size}>
                                        {size}
                                      </option>
                                    ))}
                                </select>
                              )}
                            </td>
                            <td colSpan={selectedMeasurements.length}></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="mt-3">
                      <label className="label_text">Upload Image</label>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="input_tag"
                        id="image"
                      />
                      {imageError && (
                        <p className="text-danger">{imageError}</p>
                      )}
                      {image && (
                        <div className="mt-2">
                          <img
                            src={Apipath["GetImage"] + image}
                            alt="Preview"
                            style={{ maxWidth: "100%", maxHeight: "300px" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-12 mt-3">
                    <div className="active_btn">
                      <span className="label_text">Status</span>
                      <input
                        type="checkbox"
                        id="category_checkbox"
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                      />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="d-flex justify-content-center">
                      {errorMsgText && (
                        <div className="errormsg">{errorMsgText}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-4 mt-3">
                    <button
                      className="addBtn"
                      id="save_btn"
                      onClick={() => saveSizeChart()}
                    >
                      {buttonName}
                    </button>
                    <button
                      className="addBtn"
                      id="loader_btn"
                      style={{ display: "none" }}
                      disabled
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                        style={{ height: "20px", width: "20px" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SizeChartForm;
